export enum EDevicesSources {
  NinjaOne = 'NinjaOne',
  Manual = 'Manual',
  Level = 'Level'
}
export enum EDevicesSourcesUi {
  NinjaOne = 'NinjaOne',
  Manual = 'Manual',
  Level = 'Level'
}
export enum EDevicesSourcesAsParam {
  NinjaOne = 'NINJA_ONE',
  Manual = 'MANUAL',
  Level = 'LEVEL'
}
export enum DeviceActiveStatuses {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export enum DeviceManagedStatuses {
  MANAGED = 'MANAGED',
  UNMANAGED = 'UNMANAGED',
}

export enum DeviceFilters {
  COMPANY = 'companyIds',
  CONTACT = 'contactIds',
  ACTIVE = 'isActive',
  MANAGE = 'isManaged',
  SOURCE = 'sources',
}
export enum DeviceFilterNames {
  Company = 'Company',
  Contact = 'Contact',
  Active = 'Active',
  Manage = 'Managed',
  Source = 'Sources',
}
