import { TAuthConverter, TConverter } from '../types';
import {
  EASIntegrationNames,
  EASIntegrationNamesUi,
  ECalendarIntegrationsTypes,
  ECalendarIntegrationsTypesUi,
  EDocumentationIntegrationsTypes,
  ENinjaRmmRegion,
  ENinjaRmmRegionUI,
  ERMMIntegrationsTypes,
  ERMMIntegrationsTypesUI,
  TASIntegration,
  TASIntegrationSettings,
  TASIntegrationSettingsUi,
  TASIntegrationUi,
  TCalendarIntegration,
  TCalendarIntegrationUi,
  TConfigureHuduAssetLayouts,
  TConnectGoogleCalendarIntegration,
  TConnectGoogleCalendarIntegrationUi,
  TConnectHuduIntegration,
  TCredentials,
  TCredentialsPost,
  TCredentialsPostUI,
  TCredentialsUI,
  TIntegrationParam,
  TIntegrationParamUi,
  TRMMCompany,
  TRMMCompanyMappings,
  TRMMCompanyMappingsUI,
  TRMMCompanyUI,
  TRMMIntegration,
  TRMMIntegrationUI,
  TRMMSettings,
  TRMMSettingsUI,
  TRMMSettingsUpdate,
  TRMMSettingsUpdateUi,
} from '../types/integrations.types';
import { TCreateConverter, TFetchConverter } from '../types/converter.types';

export const asIntegrationTypeConverter: TConverter<
  EASIntegrationNamesUi,
  EASIntegrationNames
> = {
  fromDb: data => {
    const convertedDataMapper: {
      [key in EASIntegrationNames]: EASIntegrationNamesUi;
    } = {
      [EASIntegrationNames.QUICKBOOKS]: EASIntegrationNamesUi.QUICKBOOKS,
      [EASIntegrationNames.XERO]: EASIntegrationNamesUi.XERO,
    };
    return convertedDataMapper[data];
  },
  toDb: data => {
    const convertedDataMapper: {
      [key in EASIntegrationNamesUi]: EASIntegrationNames;
    } = {
      [EASIntegrationNamesUi.QUICKBOOKS]: EASIntegrationNames.QUICKBOOKS,
      [EASIntegrationNamesUi.XERO]: EASIntegrationNames.XERO,
    };
    return convertedDataMapper[data];
  },
};

export const asIntegrationSettingsConverter: TConverter<
  TASIntegrationSettingsUi,
  TASIntegrationSettings
> = {
  fromDb: data => {
    return {
      id: data.id,
      allowCreditCardPayment: data.allowCreditCardPayment,
      allowBankTransferPayment: data.allowBankTransferPayment,
      allowAutoSendOnExport: data.allowAutoSendOnExport,
    };
  },
  toDb: data => {
    return {
      id: data.id,
      allowCreditCardPayment: data.allowCreditCardPayment,
      allowBankTransferPayment: data.allowBankTransferPayment,
      allowAutoSendOnExport: data.allowAutoSendOnExport,
    };
  },
};
export const integrationConverter: TFetchConverter<
  TASIntegrationUi,
  TASIntegration
> = {
  fromDb: data => {
    return {
      ...(data.id && { id: data.id }),
      type: asIntegrationTypeConverter.fromDb(data.type),
      connected: data.connected,
      settings: asIntegrationSettingsConverter.fromDb(data.settings),
    };
  },
};
export const RMMIntegrationTypeConverter: TConverter<
  ERMMIntegrationsTypesUI,
  ERMMIntegrationsTypes
> = {
  fromDb: data => {
    const convertedDataMapper: {
      [key in ERMMIntegrationsTypes]: ERMMIntegrationsTypesUI;
    } = {
      [ERMMIntegrationsTypes.NINJA]: ERMMIntegrationsTypesUI.NINJA,
      [ERMMIntegrationsTypes.LEVEL]: ERMMIntegrationsTypesUI.LEVEL,
      [ERMMIntegrationsTypes.ADDIGY]: ERMMIntegrationsTypesUI.ADDIGY,
    };
    return convertedDataMapper[data];
  },
  toDb: data => {
    const convertedDataMapper: {
      [key in ERMMIntegrationsTypesUI]: ERMMIntegrationsTypes;
    } = {
      [ERMMIntegrationsTypesUI.NINJA]: ERMMIntegrationsTypes.NINJA,
      [ERMMIntegrationsTypesUI.LEVEL]: ERMMIntegrationsTypes.LEVEL,
      [ERMMIntegrationsTypesUI.ADDIGY]: ERMMIntegrationsTypes.ADDIGY,
    };
    return convertedDataMapper[data];
  },
};

export const RMMSettingsConverter: TAuthConverter<
  TRMMSettingsUpdateUi,
  TRMMSettingsUpdate,
  TRMMSettingsUI,
  TRMMSettings
> = {
  fromDb: data => {
    return {
      syncInterval: data.syncInterval,
      id: data.id,
      ticketProcessorEnabled: data.ticketProcessorEnabled,
      webhookUri: data.webhookUri,
    };
  },
  toDb: data => {
    return {
      syncInterval: data.syncInterval,
      ticketProcessorEnabled: data.ticketProcessorEnabled,
    };
  },
};

export const RMMIntegrationConverter: TFetchConverter<
  TRMMIntegrationUI,
  TRMMIntegration
> = {
  fromDb: data => {
    return {
      connected: data.connected,
      type: RMMIntegrationTypeConverter.fromDb(data.type),
      ...(data.id ? { id: data.id } : {}),
      ...(data.settings
        ? { settings: RMMSettingsConverter.fromDb(data.settings) }
        : {}),
    };
  },
};
export const CalendarIntegrationTypeConverter: TConverter<
  ECalendarIntegrationsTypesUi,
  ECalendarIntegrationsTypes
> = {
  fromDb: data => {
    const convertedDataMapper: {
      [key in ECalendarIntegrationsTypes]: ECalendarIntegrationsTypesUi;
    } = {
      [ECalendarIntegrationsTypes.GOOGLE]: ECalendarIntegrationsTypesUi.GOOGLE,
      [ECalendarIntegrationsTypes.MICROSOFT_OUTLOOK]:
        ECalendarIntegrationsTypesUi.MICROSOFT_OUTLOOK,
    };
    return convertedDataMapper[data];
  },
  toDb: data => {
    const convertedDataMapper: {
      [key in ECalendarIntegrationsTypesUi]: ECalendarIntegrationsTypes;
    } = {
      [ECalendarIntegrationsTypesUi.MICROSOFT_OUTLOOK]:
        ECalendarIntegrationsTypes.MICROSOFT_OUTLOOK,
      [ECalendarIntegrationsTypesUi.GOOGLE]: ECalendarIntegrationsTypes.GOOGLE,
    };
    return convertedDataMapper[data];
  },
};
export const CalendarIntegrationConverter: TFetchConverter<
  TCalendarIntegrationUi,
  TCalendarIntegration
> = {
  fromDb: data => {
    return {
      connected: data.connected,
      type: CalendarIntegrationTypeConverter.fromDb(data.type),
      ...(data.id ? { id: data.id } : {}),
      ...(data.csTenantId ? { csTenantId: data.csTenantId } : {}),
    };
  },
};

export const connectGoogleCalendarIntegrationConverter: TCreateConverter<
  TConnectGoogleCalendarIntegrationUi,
  TConnectGoogleCalendarIntegration
> = {
  toDb: data => {
    return {
      type: ECalendarIntegrationsTypes.GOOGLE,
      csTenantId: data.csTenantId,
    };
  },
};

export const connectHuduIntegrationConverter: TCreateConverter<
TConnectHuduIntegration,
TConnectHuduIntegration
> = {
  toDb: data => {
    return {
      type: EDocumentationIntegrationsTypes.HUDU,
      apiKey: data.apiKey,
      domain: data.domain
    };
  },
};

export const configureHuduAssetLayoutsConverter: TCreateConverter<
TConfigureHuduAssetLayouts,
TConfigureHuduAssetLayouts
> = {
  toDb: data => {
    return {
      ids: data.ids,
      selected: data.selected
    };
  },
};

export const integrationParamConverter: TCreateConverter<
  TIntegrationParamUi,
  TIntegrationParam
> = {
  toDb: data => {
    const { state, realmId, code } = data;
    if (!state || !realmId || !code) {
      return null;
    }
    return {
      state,
      realmId,
      code,
    };
  },
};

export const NinjaRMMCredentialsRegionConverter: TConverter<
  ENinjaRmmRegionUI,
  ENinjaRmmRegion
> = {
  fromDb: data => {
    const convertedDataMapper: {
      [key in ENinjaRmmRegion]: ENinjaRmmRegionUI;
    } = {
      [ENinjaRmmRegion.AUSTRALIA]: ENinjaRmmRegionUI.AUSTRALIA,
      [ENinjaRmmRegion.CANADA]: ENinjaRmmRegionUI.CANADA,
      [ENinjaRmmRegion.EUROPE]: ENinjaRmmRegionUI.EUROPE,
      [ENinjaRmmRegion.USA]: ENinjaRmmRegionUI.USA,
    };
    return convertedDataMapper[data];
  },
  toDb: data => {
    const convertedDataMapper: {
      [key in ENinjaRmmRegionUI]: ENinjaRmmRegion;
    } = {
      [ENinjaRmmRegionUI.USA]: ENinjaRmmRegion.USA,
      [ENinjaRmmRegionUI.EUROPE]: ENinjaRmmRegion.EUROPE,
      [ENinjaRmmRegionUI.CANADA]: ENinjaRmmRegion.CANADA,
      [ENinjaRmmRegionUI.AUSTRALIA]: ENinjaRmmRegion.AUSTRALIA,
    };
    return convertedDataMapper[data];
  },
};

export const credentialsConverter: TAuthConverter<
  TCredentialsPostUI,
  TCredentialsPost,
  TCredentialsUI,
  TCredentials
> = {
  fromDb: data => {
    return {
      clientId: data.clientId,
      region: data.region,
    };
  },
  toDb: data => {
    return {
      clientId: data.clientId,
      clientSecret: data.clientSecret,
      region: NinjaRMMCredentialsRegionConverter.toDb(data.region),
    };
  },
};
export const RMMCompanyConverter: TFetchConverter<TRMMCompanyUI, TRMMCompany> =
  {
    fromDb: data => {
      return {
        companyId: data.companyId,
        mappedDevicesCount: data.mappedDevicesCount,
        zestCompanyName: data.zestCompanyName,
        notMappedDevicesCount: data.notMappedDevicesCount,
        rmmCompanyName: data.rmmCompanyName,
      };
    },
  };
export const RMMCompanyMappingsConverter: TFetchConverter<
  TRMMCompanyMappingsUI,
  TRMMCompanyMappings
> = {
  fromDb: data => {
    return {
      companies: data.companies.map(company =>
        RMMCompanyConverter.fromDb(company),
      ),
      notMappedNinjaCompanies: data.notMappedNinjaCompanies,
      notMappedZestCompanies: data.notMappedZestCompanies,
    };
  },
};
